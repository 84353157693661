
/* BASE
 ========================================================================== */

.uk-margin-xxsmall-top, .uk-margin-xxsmall {margin-top: $global-xxsmall-margin;}
.uk-margin-xxsmall-bottom {margin-bottom: $global-xxsmall-margin;}
.uk-margin-xxsmall-left {margin-left: $global-xxsmall-margin;}
.uk-margin-xxsmall-right {margin-right: $global-xxsmall-margin;}
.uk-margin-xxsmall-horizontal {margin: auto $global-xxsmall-margin;}
.uk-margin-xxsmall-vertical {margin: $global-xxsmall-margin auto;}
.uk-margin-xxsmall-all {margin: $global-xxsmall-margin;}
.uk-margin-xsmall-top, .uk-margin-xsmall {margin-top: $global-xsmall-margin;}
.uk-margin-xsmall-bottom {margin-bottom: $global-xsmall-margin;}
.uk-margin-xsmall-left {margin-left: $global-xsmall-margin;}
.uk-margin-xsmall-right {margin-right: $global-xsmall-margin;}
.uk-margin-xsmall-horizontal {margin: auto $global-xsmall-margin;}
.uk-margin-xsmall-vertical {margin: $global-xsmall-margin auto;}
.uk-margin-xsmall-all {margin: $global-xsmall-margin;}
.uk-margin-small-top, .uk-margin-small {margin-top: $global-small-margin;}
.uk-margin-small-bottom {margin-bottom: $global-small-margin;}
.uk-margin-small-left {margin-left: $global-small-margin;}
.uk-margin-small-right {margin-right: $global-small-margin;}
.uk-margin-small-horizontal {margin: auto $global-small-margin;}
.uk-margin-small-vertical {margin: $global-small-margin auto;}
.uk-margin-small-all {margin: $global-small-margin;}
.uk-margin-medium-top, .uk-margin-top, .uk-margin, .uk-margin-medium {margin-top: $global-margin;}
.uk-margin-medium-bottom, .uk-margin-bottom {margin-bottom: $global-margin;}
.uk-margin-medium-left, .uk-margin-left {margin-left: $global-margin;}
.uk-margin-medium-right, .uk-margin-right {margin-right: $global-margin;}
.uk-margin-medium-horizontal,.uk-margin-horizontal {margin: auto $global-margin;}
.uk-margin-medium-vertical,.uk-margin-vertical {margin: $global-margin auto;}
.uk-margin-medium-all, .uk-margin-all {margin: $global-margin;}
.uk-margin-large-top, .uk-margin-large {margin-top: $global-large-margin;}
.uk-margin-large-bottom {margin-bottom: $global-large-margin;}
.uk-margin-large-left {margin-left: $global-large-margin;}
.uk-margin-large-right {margin-right: $global-large-margin;}
.uk-margin-large-horizontal {margin: auto $global-large-margin;}
.uk-margin-large-vertical {margin: $global-large-margin auto;}
.uk-margin-large-all {margin: $global-large-margin;}
.uk-margin-xlarge-top {margin-top: $global-xlarge-margin;}
.uk-margin-xlarge-bottom {margin-bottom: $global-xlarge-margin;}
.uk-margin-xlarge-left {margin-left: $global-xlarge-margin;}
.uk-margin-xlarge-right {margin-right: $global-xlarge-margin;}
.uk-margin-xlarge-horizontal {margin: auto $global-xlarge-margin;}
.uk-margin-xlarge-vertical {margin: $global-xlarge-margin auto;}
.uk-margin-xlarge-all {margin: $global-xlarge-margin;}
.uk-margin-xxlarge-top {margin-top: $global-xxlarge-margin;}
.uk-margin-xxlarge-bottom {margin-bottom: $global-xxlarge-margin;}
.uk-margin-xxlarge-left {margin-left: $global-xxlarge-margin;}
.uk-margin-xxlarge-right {margin-right: $global-xxlarge-margin;}
.uk-margin-xxlarge-horizontal {margin: auto $global-xxlarge-margin;}
.uk-margin-xxlarge-vertical {margin: $global-xxlarge-margin auto;}
.uk-margin-xxlarge-all {margin: $global-xxlarge-margin;}


/* MEDIUM LAYOUT
 ========================================================================== */

@media (min-width: $breakpoint-medium) {
  .uk-margin-xxsmall-top, .uk-margin-xxsmall {margin-top: $global-xxsmall-margin-m;}
  .uk-margin-xxsmall-bottom {margin-bottom: $global-xxsmall-margin-m;}
  .uk-margin-xxsmall-left {margin-left: $global-xxsmall-margin-m;}
  .uk-margin-xxsmall-right {margin-right: $global-xxsmall-margin-m;}
  .uk-margin-xxsmall-horizontal {margin: auto $global-xxsmall-margin-m;}
  .uk-margin-xxsmall-vertical {margin: $global-xxsmall-margin-m auto;}
  .uk-margin-xxsmall-all {margin: $global-xxsmall-margin-m;}
  .uk-margin-xsmall-top, .uk-margin-xsmall {margin-top: $global-xsmall-margin-m;}
  .uk-margin-xsmall-bottom {margin-bottom: $global-xsmall-margin-m;}
  .uk-margin-xsmall-left {margin-left: $global-xsmall-margin-m;}
  .uk-margin-xsmall-right {margin-right: $global-xsmall-margin-m;}
  .uk-margin-xsmall-horizontal {margin: auto $global-xsmall-margin-m;}
  .uk-margin-xsmall-vertical {margin: $global-xsmall-margin-m auto;}
  .uk-margin-xsmall-all {margin: $global-xsmall-margin-m;}
  .uk-margin-small-top, .uk-margin-small {margin-top: $global-small-margin-m;}
  .uk-margin-small-bottom {margin-bottom: $global-small-margin-m;}
  .uk-margin-small-left {margin-left: $global-small-margin-m;}
  .uk-margin-small-right {margin-right: $global-small-margin-m;}
  .uk-margin-small-horizontal {margin: auto $global-small-margin-m;}
  .uk-margin-small-vertical {margin: $global-small-margin-m auto;}
  .uk-margin-small-all {margin: $global-small-margin-m;}
  .uk-margin-medium-top, .uk-margin-top, .uk-margin, .uk-margin-medium {margin-top: $global-margin-m;}
  .uk-margin-medium-bottom, .uk-margin-bottom {margin-bottom: $global-margin-m;}
  .uk-margin-medium-left, .uk-margin-left {margin-left: $global-margin-m;}
  .uk-margin-medium-right .uk-margin-right {margin-right: $global-margin-m;}
  .uk-margin-medium-horizontal, .uk-margin-horizontal {margin: auto $global-margin-m;}
  .uk-margin-medium-vertical, .uk-margin-vertical {margin: $global-margin-m auto;}
  .uk-margin-medium-all, .uk-margin-all {margin: $global-margin-m;}
  .uk-margin-large-top, .uk-margin-large {margin-top: $global-large-margin-m;}
  .uk-margin-large-bottom {margin-bottom: $global-large-margin-m;}
  .uk-margin-large-left {margin-left: $global-large-margin-m;}
  .uk-margin-large-right {margin-right: $global-large-margin-m;}
  .uk-margin-large-horizontal {margin: auto $global-large-margin-m;}
  .uk-margin-large-vertical {margin: $global-large-margin-m auto;}
  .uk-margin-large-all {margin: $global-large-margin-m;}
  .uk-margin-xlarge-top, .uk-margin-xlarge {margin-top: $global-xlarge-margin-m;}
  .uk-margin-xlarge-bottom {margin-bottom: $global-xlarge-margin-m;}
  .uk-margin-xlarge-left {margin-left: $global-xlarge-margin-m;}
  .uk-margin-xlarge-right {margin-right: $global-xlarge-margin-m;}
  .uk-margin-xlarge-horizontal {margin: auto $global-xlarge-margin-m;}
  .uk-margin-xlarge-vertical {margin: $global-xlarge-margin-m auto;}
  .uk-margin-xlarge-all {margin: $global-xlarge-margin-m;}
  .uk-margin-xxlarge-top, .uk-margin-xlarge {margin-top: $global-xxlarge-margin-m;}
  .uk-margin-xxlarge-bottom {margin-bottom: $global-xxlarge-margin-m;}
  .uk-margin-xxlarge-left {margin-left: $global-xxlarge-margin-m;}
  .uk-margin-xxlarge-right {margin-right: $global-xxlarge-margin-m;}
  .uk-margin-xxlarge-horizontal {margin: auto $global-xlarge-margin-m;}
  .uk-margin-xxlarge-vertical {margin: $global-xxlarge-margin-m auto;}
  .uk-margin-xxlarge-all {margin: $global-xxlarge-margin-m;}
}


/* LARGE LAYOUT
 ========================================================================== */


@media (min-width: $breakpoint-large) {
  .uk-margin-xxsmall-top, .uk-margin-xxsmall {margin-top: $global-xxsmall-margin-l;}
  .uk-margin-xxsmall-bottom {margin-bottom: $global-xxsmall-margin-l;}
  .uk-margin-xxsmall-left {margin-left: $global-xxsmall-margin-l;}
  .uk-margin-xxsmall-right {margin-right: $global-xxsmall-margin-l;}
  .uk-margin-xxsmall-horizontal {margin: auto $global-xxsmall-margin-l;}
  .uk-margin-xxsmall-vertical {margin: $global-xxsmall-margin-l auto;}
  .uk-margin-xxsmall-all {margin: $global-xxsmall-margin-l;}
  .uk-margin-xsmall-top, .uk-margin-xsmall {margin-top: $global-xsmall-margin-l;}
  .uk-margin-xsmall-bottom {margin-bottom: $global-xsmall-margin-l;}
  .uk-margin-xsmall-left {margin-left: $global-xsmall-margin-l;}
  .uk-margin-xsmall-right {margin-right: $global-xsmall-margin-l;}
  .uk-margin-xsmall-horizontal {margin: auto $global-xsmall-margin-l;}
  .uk-margin-xsmall-vertical {margin: $global-xsmall-margin-l auto;}
  .uk-margin-xsmall-all {margin: $global-xsmall-margin-l;}
  .uk-margin-small-top, .uk-margin-small {margin-top: $global-small-margin-l;}
  .uk-margin-small-bottom {margin-bottom: $global-small-margin-l;}
  .uk-margin-small-left {margin-left: $global-small-margin-l;}
  .uk-margin-small-right {margin-right: $global-small-margin-l;}
  .uk-margin-small-horizontal {margin: auto $global-small-margin-l;}
  .uk-margin-small-vertical {margin: $global-small-margin-l auto;}
  .uk-margin-small-all {margin: $global-small-margin-l;}
  .uk-margin-medium-top, .uk-margin-top, .uk-margin, .uk-margin-medium {margin-top: $global-margin-l;}
  .uk-margin-medium-bottom, .uk-margin-bottom {margin-bottom: $global-margin-l;}
  .uk-margin-medium-left, .uk-margin-left {margin-left: $global-margin-l;}
  .uk-margin-medium-right, .uk-margin-right {margin-right: $global-margin-l;}
  .uk-margin-medium-horizontal,.uk-margin-horizontal {margin: auto $global-margin-l;}
  .uk-margin-medium-vertical,.uk-margin-vertical {margin: $global-margin-l auto;}
  .uk-margin-medium-all, .uk-margin-all {margin: $global-margin-l;}
  .uk-margin-large-top, .uk-margin-large {margin-top: $global-large-margin-l;}
  .uk-margin-large-bottom {margin-bottom: $global-large-margin-l;}
  .uk-margin-large-left {margin-left: $global-large-margin-l;}
  .uk-margin-large-right {margin-right: $global-large-margin-l;}
  .uk-margin-large-horizontal {margin: auto $global-large-margin-l;}
  .uk-margin-large-vertical {margin: $global-large-margin-l auto;}
  .uk-margin-large-all {margin: $global-large-margin-l;}
  .uk-margin-xlarge-top, .uk-margin-xlarge {margin-top: $global-xlarge-margin-l;}
  .uk-margin-xlarge-bottom {margin-bottom: $global-xlarge-margin-l;}
  .uk-margin-xlarge-left {margin-left: $global-xlarge-margin-l;}
  .uk-margin-xlarge-right {margin-right: $global-xlarge-margin-l;}
  .uk-margin-xlarge-horizontal {margin: auto $global-xlarge-margin-l;}
  .uk-margin-xlarge-vertical {margin: $global-xlarge-margin-l auto;}
  .uk-margin-xlarge-all {margin: $global-xlarge-margin-l;}
  .uk-margin-xxlarge-top, .uk-margin-xxlarge {margin-top: $global-xxlarge-margin-l;}
  .uk-margin-xxlarge-bottom {margin-bottom: $global-xxlarge-margin-l;}
  .uk-margin-xxlarge-left {margin-left: $global-xxlarge-margin-l;}
  .uk-margin-xxlarge-right {margin-right: $global-xxlarge-margin-l;}
  .uk-margin-xxlarge-horizontal {margin: auto $global-xxlarge-margin-l;}
  .uk-margin-xxlarge-vertical {margin: $global-xxlarge-margin-l auto;}
  .uk-margin-xxlarge-all {margin: $global-xxlarge-margin-l;}
}


/* Auto
 ========================================================================== */

.uk-margin-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.uk-margin-auto-top { margin-top: auto !important; }
.uk-margin-auto-bottom { margin-bottom: auto !important; }
.uk-margin-auto-left { margin-left: auto !important; }
.uk-margin-auto-right { margin-right: auto !important; }

.uk-margin-auto-vertical {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

/* Phone landscape and bigger */
@media (min-width: $breakpoint-small) {

    .uk-margin-auto\@s {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .uk-margin-auto-left\@s { margin-left: auto !important; }
    .uk-margin-auto-right\@s { margin-right: auto !important; }

}

/* Tablet landscape and bigger */
@media (min-width: $breakpoint-medium) {

    .uk-margin-auto\@m {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .uk-margin-auto-left\@m { margin-left: auto !important; }
    .uk-margin-auto-right\@m { margin-right: auto !important; }

}

/* Desktop and bigger */
@media (min-width: $breakpoint-large) {

    .uk-margin-auto\@l {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .uk-margin-auto-left\@l { margin-left: auto !important; }
    .uk-margin-auto-right\@l { margin-right: auto !important; }

}

/* Large screen and bigger */
@media (min-width: $breakpoint-xlarge) {

    .uk-margin-auto\@xl {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .uk-margin-auto-left\@xl { margin-left: auto !important; }
    .uk-margin-auto-right\@xl { margin-right: auto !important; }

}


/* Remove
 ========================================================================== */

.uk-margin-remove { margin: 0 !important; }
.uk-margin-remove-top { margin-top: 0 !important; }
.uk-margin-remove-bottom { margin-bottom: 0 !important; }
.uk-margin-remove-left { margin-left: 0 !important; }
.uk-margin-remove-right { margin-right: 0 !important; }

.uk-margin-remove-vertical {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.uk-margin-remove-adjacent + *,
.uk-margin-remove-first-child > :first-child { margin-top: 0 !important; }
.uk-margin-remove-last-child > :last-child { margin-bottom: 0 !important; }

/* Phone landscape and bigger */
@media (min-width: $breakpoint-small) {

    .uk-margin-remove-left\@s { margin-left: 0 !important; }
    .uk-margin-remove-right\@s { margin-right: 0 !important; }

}

/* Tablet landscape and bigger */
@media (min-width: $breakpoint-medium) {

    .uk-margin-remove-left\@m { margin-left: 0 !important; }
    .uk-margin-remove-right\@m { margin-right: 0 !important; }

}

/* Desktop and bigger */
@media (min-width: $breakpoint-large) {

    .uk-margin-remove-left\@l { margin-left: 0 !important; }
    .uk-margin-remove-right\@l { margin-right: 0 !important; }

}

/* Large screen and bigger */
@media (min-width: $breakpoint-xlarge) {

    .uk-margin-remove-left\@xl { margin-left: 0 !important; }
    .uk-margin-remove-right\@xl { margin-right: 0 !important; }

}


// Hooks
// ========================================================================

@if(mixin-exists(hook-margin-misc)) {@include hook-margin-misc();}

// @mixin hook-margin-misc(){}
