.uk-responsive-16-9 {
    padding-top: 56.25%;
    position: relative;

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
