@media (min-width: $breakpoint-large) {
    .uk-flex\@l {
        display: flex;
    }

    .uk-flex-row\@l {
        flex-direction: row;
    }

    .uk-flex-column\@l {
        flex-direction: column;
    }
}

@media (min-width: $breakpoint-medium) {
    .uk-flex\@m {
        display: flex;
    }

    .uk-flex-row\@m {
        flex-direction: row;
    }

    .uk-flex-column\@m {
        flex-direction: column;
    }
}

@media (min-width: $breakpoint-small) {
    .uk-flex\@s {
        display: flex;
    }

    .uk-flex-row\@s {
        flex-direction: row;
    }

    .uk-flex-column\@s {
        flex-direction: column;
    }
}
