$counter-number-line-height: 1;
$counter-size: 200px;
$counter-background: $global-color;
$counter-color: $global-secondary-color;

.uk-counters{
  .uk-counter{
    border-radius: 50%;
    background-color: $counter-background;
    color: $counter-color;
    width: $counter-size;
    height: $counter-size;
  }
}
