@import '../_variables';

$select-color-item:					$global-emphasis-color;
$select-color-item-text:			$global-color;
$select-color-item-active-text:		$global-color;
$select-color-item-border:			$global-emphasis-color;
$select-color-item-active:			$global-emphasis-color;
$select-color-item-active-border:	$global-emphasis-color;
$select-width-item-border:			0px;

$select-shadow-input:				inset 0 1px 1px rgba(0,0,0,0.1) !default;
$select-shadow-input-focus:			inset 0 1px 2px rgba(0,0,0,0.15) !default;


@import "~tom-select/dist/scss/tom-select.default";
@import "~tom-select/dist/scss/plugins/remove_button";
