$button-default-background: $global-color;
$button-default-color: $global-primary-color;
$button-default-hover-color: $global-color;
$button-primary-background: $global-primary-color;
$button-primary-color: $global-color;
$button-primary-hover-color: $global-secondary-color;
$button-secondary-background: $global-secondary-color;
$button-secondary-color: $global-color;
$button-secondary-hover-color: $global-color;
$button-font-family: $base-button-font-family;

@import '~uikit/src/scss/components/button';

.uk-button {font-family: $button-font-family; }
