$countdown-number-line-height: 1;
$countdown-number-font-size: 1rem;
$countdown-number-font-size-s: 2rem;
$countdown-number-font-size-m: 2.5rem;
$countdown-background: $global-color;
$countdown-color: $global-secondary-color;

@import '~uikit/src/scss/components/countdown';

.uk-countdown-number {
  align-items: center;
  background-color: $countdown-background;
  border-radius: 50%;
  color: $countdown-color;
  display: flex;
  font-weight: bold;
  height: 75px;
  justify-content: center;
  margin: $global-small-margin;
  width: 75px;
}

@media (max-width: $breakpoint-large) {
  .uk-countdown-number {
    height: 70;
    margin: $global-xsmall-margin;
    width: 70;
  }
}

@media (max-width: $breakpoint-medium) {
  .uk-countdown-number {
    height: 50;
    width: 50;
  }
}

@media (max-width: $breakpoint-small) {
  .uk-countdown-number {
    height: 30;
    margin: $global-xxsmall-margin;
    width: 30;
  }
}
