$chat-bubble-receiver-background: $global-color;
$chat-bubble-sender-background: $global-secondary-color;
$chat-bubble-receiver-color: $global-secondary-color;
$chat-bubble-sender-color: $global-color;
$chat-readed-message-color: $global-success-background;
$avatar-size: 50px;
$notification-size: 20px;

.talk-bubble {
  position: relative;
  background: $chat-bubble-receiver-background;
  border-radius: .4em;
  color: $chat-bubble-receiver-color;
  border: $global-border-width solid $global-border;
  clear: both;
  float: right;
  padding: 0 10;
  .readed {color: $chat-readed-message-color;}
  &.senderSide {
    background: $chat-bubble-sender-background;
    float: left;
    color: $chat-bubble-sender-color;
    border: $global-border-width solid $global-border;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0.9em;
      width: 0;
      height: 0;
      border: 1em solid transparent;
      border-right-color: $global-border;
      border-left: 0;
      border-top: 0;
      margin-top: -0.5em;
      margin-left: -1em;

    }
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0.9em;
    width: 0;
    height: 0;
    border: 1em solid transparent;
    border-left-color: $global-border;
    border-right: 0;
    border-top: 0;
    margin-top: -0.5em;
    margin-right: -1em;
  }
}

// AVATAR AND INITIALS
.avatar {
  span.initials {
    display: inline-block;
    position: relative;
    line-height: $avatar-size;
    text-align: center;
    text-transform: uppercase;
  }
  img,
  span.initials {
    height: $avatar-size;
    width: $avatar-size;
  }
}

.message-received {
  width: $notification-size;
  height: $notification-size;
  margin: 0;
  z-index: 10;
  display: none;
}
