.uk-padding-xxsmall-top {padding-top: $global-xxsmall-padding;}
.uk-padding-xxsmall-left {padding-left: $global-xxsmall-padding;}
.uk-padding-xxsmall-right {padding-right: $global-xxsmall-padding;}
.uk-padding-xxsmall-bottom {padding-bottom: $global-xxsmall-padding;}
.uk-padding-xxsmall-horizontal{padding: 0px $global-xxsmall-padding;}
.uk-padding-xxsmall-vertical {padding: $global-xxsmall-padding 0px;}
.uk-padding-xxsmall-all, .uk-padding-xxsmall {padding: $global-xxsmall-padding;}
.uk-padding-xsmall-top {padding-top: $global-xsmall-padding;}
.uk-padding-xsmall-left {padding-left: $global-xsmall-padding;}
.uk-padding-xsmall-right {padding-right: $global-xsmall-padding;}
.uk-padding-xsmall-bottom {padding-bottom: $global-xsmall-padding;}
.uk-padding-xsmall-horizontal{padding: 0px $global-xsmall-padding;}
.uk-padding-xsmall-vertical {padding: $global-xsmall-padding 0px;}
.uk-padding-xsmall-all, .uk-padding-xsmall {padding: $global-xsmall-padding;}
.uk-padding-small-top {padding-top: $global-small-padding;}
.uk-padding-small-left {padding-left: $global-small-padding;}
.uk-padding-small-right {padding-right: $global-small-padding;}
.uk-padding-small-bottom {padding-bottom: $global-small-padding;}
.uk-padding-small-horizontal{padding: 0px $global-small-padding;}
.uk-padding-small-vertical {padding: $global-small-padding 0px;}
.uk-padding-small-all, .uk-padding-small {padding: $global-small-padding;}
.uk-padding-top,.uk-padding-medium-top {padding-top: $global-padding;}
.uk-padding-left,.uk-padding-medium-left {padding-left: $global-padding;}
.uk-padding-right,.uk-padding-medium-right {padding-right: $global-padding;}
.uk-padding-bottom,.uk-padding-medium-bottom {padding-bottom: $global-padding;}
.uk-padding-horizontal,.uk-padding-medium-horizontal {padding: 0px $global-padding;}
.uk-padding-vertical,.uk-padding-medium-vertical {padding: $global-padding 0px;}
.uk-padding-all,.uk-padding-medium-all, .uk-padding {padding: $global-padding;}
.uk-padding-large-top {padding-top: $global-large-padding;}
.uk-padding-large-left {padding-left: $global-large-padding;}
.uk-padding-large-right{padding-right: $global-large-padding;}
.uk-padding-large-bottom{padding-bottom: $global-large-padding;}
.uk-padding-large-horizontal {padding: 0px $global-large-padding}
.uk-padding-large-vertical{padding: $global-large-padding 0px;}
.uk-padding-large-all {padding: $global-large-padding;}
.uk-padding-xlarge-top{padding-top: $global-xlarge-padding;}
.uk-padding-xlarge-left {padding-left: $global-xlarge-padding;}
.uk-padding-xlarge-right {padding-right: $global-xlarge-padding;}
.uk-padding-xlarge-bottom {padding-bottom: $global-xlarge-padding;}
.uk-padding-xlarge-vertical{padding: $global-xlarge-padding 0px;}
.uk-padding-xlarge-horizontal {padding: 0px $global-xlarge-padding}
.uk-padding-xlarge-all {padding: $global-xlarge-padding}
.uk-padding-xxlarge-top{padding-top: $global-xxlarge-padding;}
.uk-padding-xxlarge-left {padding-left: $global-xxlarge-padding;}
.uk-padding-xxlarge-right {padding-right: $global-xxlarge-padding;}
.uk-padding-xxlarge-bottom {padding-bottom: $global-xxlarge-padding;}
.uk-padding-xxlarge-vertical{padding: $global-xxlarge-padding 0px;}
.uk-padding-xxlarge-horizontal {padding: 0px $global-xxlarge-padding;}
.uk-padding-xxlarge-all {padding: $global-xxlarge-padding;}

@media (min-width: $breakpoint-medium) {
    .uk-padding-xxsmall-top {padding-top: $global-xxsmall-padding-m;}
    .uk-padding-xxsmall-left {padding-left: $global-xxsmall-padding-m;}
    .uk-padding-xxsmall-right {padding-right: $global-xxsmall-padding-m;}
    .uk-padding-xxsmall-bottom {padding-bottom: $global-xxsmall-padding-m;}
    .uk-padding-xxsmall-horizontal{padding: 0px $global-xxsmall-padding-m;}
    .uk-padding-xxsmall-vertical {padding: $global-xxsmall-padding-m 0px;}
    .uk-padding-xxsmall-all, .uk-padding-xxsmall {padding: $global-xxsmall-padding-m;}
    .uk-padding-xsmall-top {padding-top: $global-xsmall-padding-m;}
    .uk-padding-xsmall-left {padding-left: $global-xsmall-padding-m;}
    .uk-padding-xsmall-right {padding-right: $global-xsmall-padding-m;}
    .uk-padding-xsmall-bottom {padding-bottom: $global-xsmall-padding-m;}
    .uk-padding-xsmall-horizontal{padding: 0px $global-xsmall-padding-m;}
    .uk-padding-xsmall-vertical {padding: $global-xsmall-padding-m 0px;}
    .uk-padding-xsmall-all, .uk-padding-xsmall {padding: $global-xsmall-padding-m;}
    .uk-padding-small-top {padding-top: $global-small-padding-m;}
    .uk-padding-small-left {padding-left: $global-small-padding-m;}
    .uk-padding-small-right {padding-right: $global-small-padding-m;}
    .uk-padding-small-bottom {padding-bottom: $global-small-padding-m;}
    .uk-padding-small-horizontal{padding: 0px $global-small-padding-m;}
    .uk-padding-small-vertical {padding: $global-small-padding-m 0px;}
    .uk-padding-small-all, .uk-padding-small {padding: $global-small-padding-m;}
    .uk-padding-top,.uk-padding-medium-top {padding-top: $global-padding-m;}
    .uk-padding-left,.uk-padding-medium-left {padding-left: $global-padding-m;}
    .uk-padding-right,.uk-padding-medium-right {padding-right: $global-padding-m;}
    .uk-padding-bottom,.uk-padding-medium-bottom {padding-bottom: $global-padding-m;}
    .uk-padding-horizontal,.uk-padding-medium-horizontal {padding: 0px $global-padding-m;}
    .uk-padding-vertical,.uk-padding-medium-vertical {padding: $global-padding-m 0px;}
    .uk-padding-all,.uk-padding-medium-all, .uk-padding, .uk-padding-medium {padding: $global-padding-m;}
    .uk-padding-large-top {padding-top: $global-large-padding-m;}
    .uk-padding-large-left {padding-left: $global-large-padding-m;}
    .uk-padding-large-right{padding-right: $global-large-padding-m;}
    .uk-padding-large-bottom{padding-bottom: $global-large-padding-m;}
    .uk-padding-large-horizontal {padding: 0px $global-large-padding-m}
    .uk-padding-large-vertical{padding: $global-large-padding-m 0px;}
    .uk-padding-large-all, .uk-padding-large {padding: $global-large-padding-m;}
    .uk-padding-xlarge-top{padding-top: $global-xlarge-padding-m;}
    .uk-padding-xlarge-left {padding-left: $global-xlarge-padding-m;}
    .uk-padding-xlarge-right {padding-right: $global-xlarge-padding-m;}
    .uk-padding-xlarge-bottom {padding-bottom: $global-xlarge-padding-m;}
    .uk-padding-xlarge-vertical{padding: $global-xlarge-padding-m 0px;}
    .uk-padding-xlarge-horizontal {padding: 0px $global-xlarge-padding-m}
    .uk-padding-xlarge-all, .uk-padding-xlarge {padding: $global-xlarge-padding-m}
    .uk-padding-xxlarge-top{padding-top: $global-xxlarge-padding-m;}
    .uk-padding-xxlarge-left {padding-left: $global-xxlarge-padding-m;}
    .uk-padding-xxlarge-right {padding-right: $global-xxlarge-padding-m;}
    .uk-padding-xxlarge-bottom {padding-bottom: $global-xxlarge-padding-m;}
    .uk-padding-xxlarge-vertical{padding: $global-xxlarge-padding-m 0px;}
    .uk-padding-xxlarge-horizontal {padding: 0px $global-xxlarge-padding-m;}
    .uk-padding-xxlarge-all, .uk-padding-xxlarge {padding: $global-xxlarge-padding-m;}
}


@media (min-width: $breakpoint-large) {
    .uk-padding-xxsmall-top {padding-top: $global-xxsmall-padding-l;}
    .uk-padding-xxsmall-left {padding-left: $global-xxsmall-padding-l;}
    .uk-padding-xxsmall-right {padding-right: $global-xxsmall-padding-l;}
    .uk-padding-xxsmall-bottom {padding-bottom: $global-xxsmall-padding-l;}
    .uk-padding-xxsmall-horizontal{padding: 0px $global-xxsmall-padding-l;}
    .uk-padding-xxsmall-vertical {padding: $global-xxsmall-padding-l 0px;}
    .uk-padding-xxsmall-all, .uk-padding-xxsmall {padding: $global-xxsmall-padding-l;}
    .uk-padding-xsmall-top {padding-top: $global-xsmall-padding-l;}
    .uk-padding-xsmall-left {padding-left: $global-xsmall-padding-l;}
    .uk-padding-xsmall-right {padding-right: $global-xsmall-padding-l;}
    .uk-padding-xsmall-bottom {padding-bottom: $global-xsmall-padding-l;}
    .uk-padding-xsmall-horizontal{padding: 0px $global-xsmall-padding-l;}
    .uk-padding-xsmall-vertical {padding: $global-xsmall-padding-l 0px;}
    .uk-padding-xsmall-all, .uk-padding-xsmall {padding: $global-xsmall-padding-l;}
    .uk-padding-small-top {padding-top: $global-small-padding-l;}
    .uk-padding-small-left {padding-left: $global-small-padding-l;}
    .uk-padding-small-right {padding-right: $global-small-padding-l;}
    .uk-padding-small-bottom {padding-bottom: $global-small-padding-l;}
    .uk-padding-small-horizontal{padding: 0px $global-small-padding-l;}
    .uk-padding-small-vertical {padding: $global-small-padding-l 0px;}
    .uk-padding-small-all, .uk-padding-small {padding: $global-small-padding-l;}
    .uk-padding-top,.uk-padding-medium-top {padding-top: $global-padding-l;}
    .uk-padding-left,.uk-padding-medium-left {padding-left: $global-padding-l;}
    .uk-padding-right,.uk-padding-medium-right {padding-right: $global-padding-l;}
    .uk-padding-bottom,.uk-padding-medium-bottom {padding-bottom: $global-padding-l;}
    .uk-padding-horizontal,.uk-padding-medium-horizontal {padding: 0px $global-padding-l;}
    .uk-padding-vertical,.uk-padding-medium-vertical {padding: $global-padding-l 0px;}
    .uk-padding-all,.uk-padding-medium-all, .uk-padding, .uk-padding-medium {padding: $global-padding-l;}
    .uk-padding-large-top {padding-top: $global-large-padding-l;}
    .uk-padding-large-left {padding-left: $global-large-padding-l;}
    .uk-padding-large-right{padding-right: $global-large-padding-l;}
    .uk-padding-large-bottom{padding-bottom: $global-large-padding-l;}
    .uk-padding-large-horizontal {padding: 0px $global-large-padding-m}
    .uk-padding-large-vertical{padding: $global-large-padding-l 0px;}
    .uk-padding-large-all, .uk-padding-large {padding: $global-large-padding-l;}
    .uk-padding-xlarge-top{padding-top: $global-xlarge-padding-l;}
    .uk-padding-xlarge-left {padding-left: $global-xlarge-padding-l;}
    .uk-padding-xlarge-right {padding-right: $global-xlarge-padding-l;}
    .uk-padding-xlarge-bottom {padding-bottom: $global-xlarge-padding-l;}
    .uk-padding-xlarge-vertical{padding: $global-xlarge-padding-l 0px;}
    .uk-padding-xlarge-horizontal {padding: 0px $global-xlarge-padding-m}
    .uk-padding-xlarge-all, .uk-padding-xlarge {padding: $global-xlarge-padding-m}
    .uk-padding-xxlarge-top{padding-top: $global-xxlarge-padding-l;}
    .uk-padding-xxlarge-left {padding-left: $global-xxlarge-padding-l;}
    .uk-padding-xxlarge-right {padding-right: $global-xxlarge-padding-l;}
    .uk-padding-xxlarge-bottom {padding-bottom: $global-xxlarge-padding-l;}
    .uk-padding-xxlarge-vertical{padding: $global-xxlarge-padding-l 0px;}
    .uk-padding-xxlarge-horizontal {padding: 0px $global-xxlarge-padding-l;}
    .uk-padding-xxlarge-all, .uk-padding-xxlarge {padding: $global-xxlarge-padding-l;}
}

/* Remove
 ========================================================================== */

.uk-padding-remove { padding: 0 !important; }
.uk-padding-remove-top { padding-top: 0 !important; }
.uk-padding-remove-bottom { padding-bottom: 0 !important; }
.uk-padding-remove-left { padding-left: 0 !important; }
.uk-padding-remove-right { padding-right: 0 !important; }

.uk-padding-remove-vertical {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.uk-padding-remove-horizontal {
    padding-left: 0 !important;
    padding-right: 0 !important;
}


// Hooks
// ========================================================================

@if(mixin-exists(hook-padding-misc)) {@include hook-padding-misc();}

// @mixin hook-padding-misc(){}
