$django-site-backgroundimage: "/var/www/media/CACHE/images/uploads/sites/testwishtobetherecom/bg_hXCDtGl/20bda00fc31dde8e52f35d80362ff2d5.jpg?2024-04-09T09:34:56.116116+00:00";
                  $django-header-backgroundimage: "null";
                  $django-color: #ffffff;
                  $django-primary-color: #2529f5;
                  $django-secondary-color: #dddddd;
                  $django-inverse-color: #2529f5;
                  $django-emphasis-color: #2529f5;
                  $django-muted-color: #eeeeee;




@import '../_variables';


$global-font-family: Verdana, Geneva, Helvetica, Tahoma, sans-serif;


$base-heading-font-family: Verdana, Geneva, Helvetica, Tahoma, sans-serif;


$base-button-font-family: Verdana, Geneva, Helvetica, Tahoma, sans-serif;


$base-nav-item-font-family: Verdana, Geneva, Helvetica, Tahoma, sans-serif;




@import "../frontend.scss"
