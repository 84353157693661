// BASES
.uk-border-top {border-top: $global-border-width solid $global-border;}
.uk-border-bottom {border-bottom: $global-border-width solid $global-border;}
.uk-border-left {border-left: $global-border-width solid $global-border;}
.uk-border-right {border-right: $global-border-width solid $global-border;}
.uk-border-horizontal {border: 0 $global-border-width solid $global-border;}
.uk-border-vertcal {border: $global-border-width 0 solid $global-border;}
.uk-border {border: $global-border-width solid $global-border;}
// MODIFIERS
.uk-border-primary {border-color: $global-primary-border;}
.uk-border-muted {border-color: $global-muted-border;}
.uk-border-large {border-width: $global-border-l-width;}
