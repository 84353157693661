.uk-rotated-90 {
    writing-mode: vertical-rl;
}

.uk-rotated-180 {
    transform: rotate(180deg);
}

.uk-rotated-270 {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}

@media (min-width: $breakpoint-large) {
    .uk-rotated-90\@l {
        writing-mode: vertical-rl;
    }

    .uk-rotated-180\@l {
        transform: rotate(180deg);
    }

    .uk-rotated-270\@l {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
    }
}

@media (min-width: $breakpoint-medium) {
    .uk-rotated-90\@m {
        writing-mode: vertical-rl;
    }

    .uk-rotated-180\@m {
        transform: rotate(180deg);
    }

    .uk-rotated-270\@m {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
    }
}

@media (min-width: $breakpoint-small) {
    .uk-rotated-90\@s {
        writing-mode: vertical-rl;
    }

    .uk-rotated-180\@s {
        transform: rotate(180deg);
    }

    .uk-rotated-270\@s {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
    }
}
