// FONTS

body {
  font-family: $global-font-family;
}

.uk-h1,
.uk-h2,
.uk-h3,
.uk-h4,
.uk-h5,
.uk-h6,
.uk-heading-2xlarge,
.uk-heading-large,
.uk-heading-medium,
.uk-heading-small,
.uk-heading-xlarge,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $base-heading-font-family;
  font-weight: 700;
}

.uk-h1, h1 {
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}

body, .uk-text-normal {
  font-size: $global-medium-font-size;
}

.uk-text-xxsmall {
  font-size: $global-2xsmall-font-size;
}

.uk-text-xsmall {
  font-size: $global-xsmall-font-size;
}

.uk-text-xlarge {
  font-size: $global-xlarge-font-size;
}

.uk-text-xxlarge {
  font-size: $global-2xlarge-font-size;
}
.uk-title{
  color: $global-primary-color;
  background: $global-color;
  padding-top: $global-xsmall-padding;
  padding-bottom: $global-xsmall-padding;
}

em {color:  $global-color;}

@media (min-width: $breakpoint-medium) {

  .uk-text-xxsmall {
    font-size: $global-2xsmall-font-size-m;
  }

  .uk-text-xsmall {
    font-size: $global-xsmall-font-size-m;
  }

  .uk-text-small {
    font-size: $global-small-font-size-m;
  }

  body, .uk-text-normal {
    font-size: $global-medium-font-size-m;
  }

  .uk-text-large {
    font-size: $global-large-font-size-m;
  }

  .uk-text-xlarge {
    font-size: $global-xlarge-font-size-m;
  }

  .uk-text-xxlarge {
    font-size: $global-2xlarge-font-size-m;
  }
}
